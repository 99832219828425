
body {
  font-family: Futura;
  font-style: normal;
  background: url("./background.webp") center center no-repeat;
  background-size: 100% 100%;
  max-width: 100vw;
  overflow: hidden;
  margin: 0;
  padding: 0 !important;
  min-height: 100vh;
}
@font-face {
  font-family: Futura;
  src: url("./Futura.otf");
}


.main {
  min-height: 100vh;
  overflow: hidden;
  box-sizing: border-box;
  position: relative;
}

.main-content {
  display: flex;
  justify-content: center;
  width: 70%;
  margin: auto;
  align-items: center;
  flex-direction: row-reverse;
  min-height: 100vh;
  position: relative;

}

.imageleft {
  width: 500px;
  position: relative;
  border-radius: 5px;
  border: 1px solid #9cc57f  ;
}
.countdown{
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-bottom: 20px;
}
.countdown span{
  width: 50px;
  height: 50px;
  border-radius: 5px;
  background: black  ;
  color: #9cc57f;
  font-weight: 600;
  font-size: 24px;
  display: flex;
  justify-content: center;
  align-items: center;


}

@keyframes clipMe {

  0%,
  100% {
    clip: rect(0px, 520px, 2px, 0px);
  }

  25% {
    clip: rect(0px, 2px, 520px, 0px);
  }

  50% {
    clip: rect(518px, 520px, 520px, 0px);
  }

  75% {
    clip: rect(0px, 520px, 520px, 518px);
  }
}

.imageleft img {
  width: 100%;
  display: block;
  margin-inline: auto;
  border-radius: 5px;
  position: relative;
}



.imageleft::before,
.imageleft::after {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 5px;
}


.imageleft::before,
.imageleft::after {
  content: "";
  z-index: -1;
  margin: -2%;
  color: #9cc57f  ;
  box-shadow: inset 0 0 0 2px;
  animation: clipMe 8s linear infinite;
}

.imageleft::before {
  animation-delay: -4s;
}

.vertical-main-containerr {
  display: grid;
}

.containerr {
  /* From https://css.glass */
  background: rgba(0, 0, 0, 0.8);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(0, 0, 0, 0.3);
  display: flex;
  flex-flow: column nowrap;
  padding: 30px;
  border-radius: 8px;
  color: #fff;
  width: 40%;
}

.minted {
  margin-bottom: 5px;
  font-size: 13px;
  color: rgba(255, 255, 255, 0.452);
  font-family: Futura;
}

.progress {
  text-align: left;
  width: 100%;
}

select {
  display: block;
  margin: 0;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23242835%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E'),
    linear-gradient(to bottom, transparent 0%, transparent 100%);
  background-repeat: no-repeat, repeat;
  background-position: right 1rem top 0.9rem, 0 0;
  background-size: 0.65em auto, 100%;
  padding: 0.4rem 2rem 0.4rem 1rem;
  scrollbar-width: none;
  -ms-overflow-style: none;
  border: 1px solid #9cc57f;
  border-radius: 8px;
  line-height: 1.5;
  color: #9cc57f;
  transition: all 200ms ease-in-out;
  font-size: 0.889rem;
}

.containerr .network-select {
  flex-flow: column;
  margin-bottom: 0.85rem;
}

.network-select label {
  font-size: 0.8rem;
}

div.account-center-actions {
  flex-direction: column;
}

div.account-center-actions>div {
  margin: 8px;
}

.bn-demo-button {
  background: #ffffffee;
  font-size: 0.889rem;
  border: 1px solid #eeeeee;
  border-radius: 8px;
  padding: 0.55rem 1.4rem;
  color: #9cc57f;
  margin: 0.4rem 0.75rem;
  transition: background 200ms ease-in-out, color 200ms ease-in-out;
}

.bn-demo-button:focus {
  outline: none;
}

.bn-demo-button:hover {
  cursor: pointer;
}

.selected-toggle-btn {
  background: #9cc57f;
  color: white;
}

.unselected-toggle-btn {
  color: #9cc57f;
  background: #ffffffee;
}

.ui-settings {
  text-align: center;
}

.ui-settings .conditional-ui-settings {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 1375px) {
  .bn-logo-link {
    position: initial;
    margin: auto;
  }
}

@media screen and (max-width: 750px) {
  body {
    overflow: unset;
    overflow-x: hidden;
  }

  main {
    height: unset;
    overflow: unset;
  }

  section {
    flex-direction: column;
  }

  div.containerr {
    width: unset;
  }
}

@media screen and (max-width: 770px) {
  .main-content {
    flex-direction: column;
    align-items: center;
  }

  div.containerr {
    padding: 1rem;
    margin-bottom: 2rem;
    width: calc(100vw - 4rem);
    text-align: center;
  }
}

@media screen and (max-width: 450px) {
  .containerr .send-transaction-containerr {
    flex-direction: column;
    margin: 4px auto;
  }
}

.minting {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
}

.mintbutton {
  background: black;
  color: white;
  border-radius: 5px;
  font-size: 20px;
  padding: 10px 80px;
  font-weight: 800;
  cursor: pointer;
  border: 2px solid #9cc57f  ;
  transition: 0.1s all ease-in-out;
  margin-inline: auto;
  display: block;
}

.mintbutton:hover {
  box-shadow: 1px 1px 20px #9cc57f  ;
  background: transparent;
  padding: 10px 90px;
  border-radius: 10px;
}



.MuiLinearProgress-bar {
  border-radius: 30px !important;
  background: #9cc57f !important;
}

.containerr h2 {
  text-align: center;
  font-family: Futura;
  letter-spacing: -4px;
  font-weight: 100;
  font-size: 50px;
  margin-top: 0px;
  margin-bottom: 30px;
}

.loadscr {
  display: flex;
  position: fixed;
  width: 100%;
  height: 100%;
  background: #000000;
  justify-content: center;
  align-items: center;
  z-index: 100;
}

.loadscr img {
  width: 30%;
}

@keyframes moveout {
  0% {
    transform: translateY(0px);
    opacity: 1;
    z-index: 100;
  }

  50% {
    transform: translateY(0px);
    opacity: 1;
    z-index: 100;
  }

  100% {
    transform: translateY(-100%);
    opacity: 0;
    display: none;
  }
}

.cost h3 {
  font-family: Futura;
  color: #fff;
  font-size: 50px;
  font-weight: 100;
  margin-top: 5px;
  letter-spacing: -2px;
  margin-bottom: 5px;

}
.labelwl{
  width: 200px;
  text-align: center;
  background: black;
  color: white;
  border-radius: 5px;
  font-size: 20px;
  padding: 5px 10px;
  font-weight: 800;
  user-select: none;
  border: 2px solid #9cc57f  ;
}
.cost h3 span {
  font-family: Futura;
  font-size: 30px;
  font-weight: 100;
}

.cost h2 {
  font-family: Futura;
  font-size: 13px;
  color: rgba(255, 255, 255, 0.452);
  margin-bottom: 0px;
  text-align: left;
  font-weight: 400;
  letter-spacing: 1px;
}

.quantitymint {
  display: flex;
  justify-content: space-between;
  height: 30px;
  border: 1px solid rgba(255, 255, 255, 0.226);
  padding: 10px;
  align-items: center;
  border-radius: 7px;
}

.quantitymint h2 {
  font-family: Futura;
  font-size: 13px;
  color: rgba(255, 255, 255, 0.671);
  font-weight: 400;
  margin-bottom: 0px !important;
}

input {
  background: transparent;
  border: none;
  font-family: Futura Guy, cursive;
  font-size: 25px;
  color: white;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.address,
.owner {
  color: white;
}

nav {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  padding: 10px;
  gap: 50px;
  position: relative;
  padding-top: 15vh;

  width: 1100px;
  margin: auto;
  border-bottom: 1px solid rgba(255, 255, 255, 0.178);
}

nav * {
  color: white;
  text-decoration: none;
  font-family: Futura;
  font-size: 20px;
}

nav *:hover {
  border-bottom: 1px solid white;
  transition: 500ms;
}

#logout {
  display: none;
}

.ownergate {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 1100px;
  margin: auto;
  padding-top: 20vh;
}

.quantitymint {
  margin-bottom: 10px;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }
}

.modal-dialog {
  position: relative;
  width: auto;
  pointer-events: none;
  padding-top: 30vh;
}

.modal-backdrop.show {
  opacity: .75;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 98vw;
  height: 100vh;
  background-color: #000;

}

.fade {
  transition: opacity .15s linear;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1055;
  display: block;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.modal-header {
  display: flex;
  overflow-x: hidden;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #adadad;
  border-top-left-radius: calc(.3rem - 1px);
  border-top-right-radius: calc(.3rem - 1px);
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  overflow-x: hidden;
  padding: 1rem;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 0;
  align-items: center;
  justify-content: flex-end;
  padding: .75rem;
  border-top: 1px solid #adadad;
  border-bottom-right-radius: calc(.3rem - 1px);
  border-bottom-left-radius: calc(.3rem - 1px);
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: rgb(211, 211, 211);
  background-clip: padding-box;
  border: 2px solid rgb(8, 8, 8);
  border-radius: .3rem;
  outline: 0;
  font-family: Futura;
}

.btn {
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: .375rem .75rem;
  font-size: 1rem;
  border-radius: .25rem;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.btn-secondary {
  color: #000;
  background-color: yellow;
  border-color: yellow;
  font-family: Futura;
  font-weight: 600;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
  font-family: Futura;
  font-weight: 800;
  font-size: 25px;
}

.modal-header .btn-close {
  padding: .5rem .5rem;
  margin: -.5rem -.5rem -.5rem auto;
}

.btn-close {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: .25em .25em;
  color: #000;
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
  border: 0;
  border-radius: .25rem;
  opacity: .5;
}

.quantitybuttons {
  display: flex;
  flex-direction: column;
}

.arrowup {
  border-color: transparent transparent #fff;
  border-style: solid;
  border-width: 0 6px 8px;
  height: 0;
  margin-bottom: 2px;
  width: 0;
  opacity: 0.7;
  cursor: pointer;
}

.arrowdown {
  border-color: #fff transparent transparent;
  border-style: solid;
  border-width: 8px 6px 0;
  height: 0;
  margin-top: 2px;
  width: 0;
  cursor: pointer;
}

.phases {

  display: flex;
  justify-content: center;
  gap: 20px;
  width: 100%;
  margin: auto;
  margin-bottom: 30px;


}

.phase {
  font-family: Futura;
  font-size: 16px;
  padding: 5px 10px;
  border-radius: 10px;
  background-color: transparent;
  border: 2px solid rgb(255, 255, 255);
  color: rgb(255, 255, 255);
  font-weight: 700;
  width: 100px;
  text-align: center;
  cursor: pointer;
  opacity: 0.2;
}

.active {
  color: white;
  background: #9cc57f;
  border: 2px solid #9cc57f;
  opacity: 1;
}

.date {
  border: 1px solid #9cc57f;
  border-radius: 5px;
  color: #9cc57f;
  padding: 3px 10px;
  font-family: Futura;
  font-size: 13px;
}

.dates {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px !important;
  text-align: center;

}

.datetext {
  font-size: 13px;
  color: rgba(255, 255, 255, 0.452);
  font-family: Futura;
}


.wlcheckwrap {
  display: flex;
  flex-direction: column;
}

.wlcheckwrap h3 {
  font-weight: 300;
  font-family: Futura;
  margin-top: 5px;
  font-size: 16px;
}
.wrapper{
  margin-top: 50px;
}
.wrapper-section{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  padding: 5px;
  border: 1px solid #9cc57f9c;
  border-radius: 5px;
}
.wrapper-section input{
  border: 1px solid rgba(220, 220, 220, 0.363);
  border-radius: 5px;
  width: 60%;
  font-size: 18px;
  font-family: Futura;
}
.wrapper-section button{
  border: none;
  background-color: #9cc57f91;
  color: white;
  font-size: 16px;
  border-radius: 5px;
  padding: 5px 10px;
  cursor: pointer;
  font-family: Futura;
}
@media(max-width: 800px) {
  .main-content {
    width: 100%;
  }

  .imageleft {
    width: 80%;
  }

  .imageleft img {
    width: 100%;
  }

  .containerr h2 {
    font-size: 30px;
  }

  .phases {
    width: 100%;
    justify-content: center;
  }

  .cost h3 {
    font-size: 30px;
    text-align: left;
  }

  .quantitymint h2 {
    font-size: 10px;
  }

  .main {
    overflow-y: visible;
  }

  .shadow {
    display: none;
  }

  .main-content {
    justify-content: center;
    gap: 20px;

  }
  .containerr{
    padding-bottom: 20px;
    background:rgba(0, 0, 0, 0.8);
  }

  .imageleft {
    width: 310px;
  }

  @keyframes clipMeMobile {

    0%,
    100% {
      clip: rect(0px, 330px, 2px, 0px);
    }

    25% {
      clip: rect(0px, 2px, 330px, 0px);
    }

    50% {
      clip: rect(328px, 330px, 330px, 0px);
    }

    75% {
      clip: rect(0px, 330px, 330px, 328px);
    }
  }

  .imageleft::before,
  .imageleft::after {
    content: "";
    z-index: -1;
    margin: -2%;
    color: #9cc57f  ;
    box-shadow: inset 0 0 0 2px;
    animation: clipMeMobile 8s linear infinite;
  }
  .imageleft::before {
    animation-delay: -4s;
  }
  *{
    overflow-y: visible;
  }
  .imageleft{
    margin-top: 20px;
  }
  .dates{
    margin-bottom: 20px!important;
  }
  .containerr{
    backdrop-filter: none;
    -webkit-backdrop-filter: none;
  }
}
.quantitymint h2{
  letter-spacing: normal;
}